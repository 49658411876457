import axios from 'axios';
import { detect } from 'detect-browser';

const endpoint = process.env.GATSBY_LOG_ENDPOINT;
const browser = detect();

const logVisit = async () => {
  console.log(endpoint);
  try {
    const visitorId = localStorage.getItem('visitorId');

    const getIpRes = await axios.get('https://geolocation-db.com/json/');

    const res = await axios.post(`${endpoint}/visit`, {
      visitorId: visitorId,
      geolocation: getIpRes.data,
    });

    if (res.data.visitorId) {
      localStorage.setItem('visitorId', res.data.visitorId);
    }

    return localStorage.getItem('visitorId');
  } catch (e) {}
};

const logPageView = async (visitorId, pageSlug) => {
  try {
    const data = {
      visitorId: visitorId,
      pageSlug: pageSlug,
      deviceType: browser.type,
      browser: browser.name,
    };

    await axios.post(`${endpoint}/page`, data);
  } catch (e) {}
};

export const logAnalysis = async (pageSlug) => {
  const visitorId = await logVisit();
  await logPageView(visitorId, pageSlug);
};
